import Plaatjie from '@ubo/plaatjie'
import React, { useEffect } from 'react'
import styled from 'styled-components'

import Overlay from 'img/overlay.png'
import PlayBtn from 'img/Play-orange.inline.svg'
import Close from 'img/close-white.inline.svg'

import ParseContent from 'components/shared/ParseContent'
import { motion, AnimatePresence } from 'framer-motion'

const VideoItem = styled.div`
  grid-column: span 1 / span 1;
  background-color: ${(props) => props.theme.color.face.secondary};
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.30);
  border-radius: 29px;

  & h3 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size[20]};
    color: ${(props) => props.theme.color.face.main};
    font-weight: 700;

    @media (min-width: 992px){
      font-size: ${(props) => props.theme.font.size[28]};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.color.face.main};

    & h3, & p, & span {
      color: ${(props) => props.theme.color.face.light};
    }

    & svg.arrow path {
      stroke: ${(props) => props.theme.color.face.light};
    }
  }
`

const Row = styled.div`
  display: grid;
  gap: 2rem;

  @media (min-width: 992px){
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

`

const Image = styled(Plaatjie)`
  width: 100%;
  height: 285px;
  object-fit: cover;


  @media (max-width: 991px){
    height: 200px;
  }
`

const OverlayImg = styled.div`
  z-index: 1;
   width: 100%;
  height: 285px;
  object-fit: cover;
  background: #5C5C5C;
  opacity: 0.15;

  @media (max-width: 991px){
    height: 200px;
  }
`

const Play = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:2;
`

const Content = styled(ParseContent)`
  & p {
    font-size: ${(props) => props.theme.font.size[14]};
    color: ${(props) => props.theme.color.face.main};
    line-height: 28px;
  }
`

const ModalContainer = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.color.face.main};
  z-index: 200;
`

const CloseDiv = styled.div`
  top: 1rem;
  right: 1rem;
  z-index: 2;
`

const Title = styled.p`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: 35px;
  color: #fff;
  font-weight: 500;
  line-height: 100px;
  opacity: 0.15;

  @media (min-width: 992px){
    font-size: 80px;
  }

  @media (min-width: 1200px){
    font-size: 120px;
  }

`

const ModalTitle = styled.h3`
  font-family: ${(props) => props.theme.font.family.secondary};
  color: ${(props) => props.theme.color.face.light};

  @media (min-width: 992px){
    font-size: ${(props) => props.theme.font.size[28]};
  }
`

const ModalContent = styled(ParseContent)`
  color: ${(props) => props.theme.color.face.light};
  font-size: ${(props) => props.theme.font.size[14]};

  & a {
    text-decoration: underline;
  }
`

const ModalVideo = styled.iframe`
  max-width: 100%;
  min-height: 300px;
  height: auto;

  @media (min-width: 992px){
    height: 400px;
  }
`

const Next = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;

   @media (max-width: 991px){
    right: 0;
  }

  & span {
    color: #fff;

    @media (max-width: 991px){
      display: none;
    }
  }

  & svg {
    width: 55px;
    height: 55px;

     @media (max-width: 991px){
      width: 35px;
    height: 35px;
    }
  }
`

const Prev = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;

  @media (max-width: 991px){
    left: 0;
  }

  & span {
    color: #fff;

    @media (max-width: 991px){
      display: none;
    }
  }

  & svg {
    width: 55px;
    height: 55px;
    transform: rotate(180deg);

      @media (max-width: 991px){
      width: 35px;
    height: 35px;
    }
  }
`


interface VideosProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Videos
}

const Videos: React.FC<VideosProps> = ({ fields }) => {
  const [showModal, setShowModal] = React.useState({
    show: false,
    title: '',
    description: '',
    videoUrl: '',
    currentIndex: 0,
  })

  const openModal = (index: number) => {
    const video = fields?.item?.[index];
    setShowModal({
      show: true,
      title: video?.title || '',
      description: video?.description || '',
      videoUrl: video?.videoUrl || '',
      currentIndex: index,
    });
  };

  const closeModal = () => {
    setShowModal((prev) => ({ ...prev, show: false }));
  };

  const nextVideo = () => {
    const nextIndex = (showModal.currentIndex + 1) % fields?.item?.length;
    openModal(nextIndex);
  };

  const prevVideo = () => {
    const nextIndex = (showModal.currentIndex + -1) % fields?.item?.length;
    openModal(nextIndex);
  };

  useEffect(() => {
    if (showModal.show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  })

  return (
    <section>
      <AnimatePresence>
        {showModal.show && (
          <ModalContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="p-3 px-lg-5 position-relative h-100" style={{ overflow: 'hidden' }}>
              <CloseDiv className="position-absolute" aria-hidden role="button" onClick={() => closeModal()}><Close /></CloseDiv>
              <div className="h-100 px-lg-5" style={{ overflow: 'auto', paddingRight: '17px' }}>
                <Prev role="button" onClick={prevVideo} className="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                  <span>Prev</span>
                </Prev>
                <Title className="mb-lg-5">HOW IT WORKS</Title>
                <ModalTitle className="mb-4">{showModal?.title}</ModalTitle>
                <div className="d-flex flex-wrap mt-4">
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <ModalVideo src={showModal?.videoUrl} width="645" height="400" frameBorder="0" allowFullScreen title={showModal?.title} />
                  </div>
                  <div className="col-lg-6 pb-5 pb-lg-0 pr-lg-5">
                    <ModalContent content={showModal?.description} className="pb-5 pb-lg-0" />
                  </div>
                </div>
                <Next role="button" onClick={nextVideo} className="d-flex align-items-center">
                  <span>Next</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </Next>
              </div>
            </div>
          </ModalContainer>
        )}
      </AnimatePresence>


      <div className="container py-4 py-lg-5">
        <Row>
          {fields?.item?.map((item, index: number) => (
            <VideoItem className="p-4 position-relative" key={index} aria-hidden role="button" onClick={() => openModal(index)}>
              <div className="p-2">
                <h3 className="mb-3">{item?.title}</h3>
                <div className="position-relative mb-3" aria-hidden role="button">
                  <Play><PlayBtn /></Play>
                  <OverlayImg alt="" className="position-absolute w-100" />
                  <Image image={item?.videoPreview} alt={item?.title || ''} />
                </div>
                <Content content={item?.shortDescription} />
                <div aria-hidden className="d-flex align-items-center mt-3 justify-content-end" role="button">
                  <span className="mr-2 font-weight-bold">Read more</span>
                  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow">
                    <path d="M15.4707 8.42713L2.0811 8.42713" stroke="#100B3D" strokeWidth="3" strokeLinecap="round" />
                    <path d="M10.2923 15L17 8.34816L10.2923 1.69633" stroke="#100B3D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
            </VideoItem>
          ))}
        </Row>
      </div>
    </section>
  )
}

export default Videos
